/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* root css */
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 14px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  @apply !font-body-font;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

input,
select,
button,
textarea,
a {
  border-radius: 6 !important;
  -webkit-appearance: none;
}

/* antd customize start */

.ant-btn-primary {
  @apply text-txt-white bg-color-primary border-color-primary hover:!bg-color-secondary hover:border-color-secondary active:!bg-color-primary disabled:text-txt-grey disabled:bg-bg-gray disabled:border-bg-gray;
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 2px solid !important;
  @apply border-color-primary text-center;
}

.ant-message-custom-content {
  @apply inline-flex items-center;
}

.ant-popover-title {
  @apply bg-bg-black;
}

.ant-image-preview-img {
  margin: auto !important;
}

.ant-image-preview-img-wrapper img {
  display: inline !important;
}

.ant-layout-header {
  padding-inline: 0 !important;
}

.ant-tabs-tab {
  @apply !text-[12px] font-bold md:!text-[20px];
}

.ant-picker-calendar-date-content {
  @apply !h-[25px];
}
.ant-picker-content thead tr {
  @apply !h-[40px] border-b-bg-black border-b-2;
}

/* antd customize end */

/* app components style start */

.page-loader {
  @apply w-full h-[50vh] flex items-center justify-center z-z-fixed;
}

.default-transition {
  @apply transition-all duration-200;
}

.two-grid-column {
  @apply flex flex-col items-center justify-center md:flex-row md:justify-between md:space-x-4;
}

/* app components style end */

/* data table style start */

.table-layout {
  @apply w-full shadow bg-bg-white rounded my-2;
}
.table-layout-container {
  @apply border-t-2 border-l-2 border-gray-200 w-full rounded bg-bg-white overflow-x-auto;
}

.data-table {
  @apply w-full leading-normal font-text-font border-spacing-0 border-collapse overflow-hidden;
}
.data-table-head {
  background: rgba(205, 205, 205, 0.11);
  @apply tracking-wider text-left px-5 py-3 border-b-2 border-gray-200 hover:cursor-default;
}
.data-table-head-tr {
  @apply border-b-2 border-gray-200;
}
.data-table-head-tr-th {
  @apply border-r-2 py-3 px-3 text-[12px] text-gray-600 font-body-font font-bold uppercase tracking-wider whitespace-nowrap;
}
.data-table-body-tr {
  @apply hover:bg-gray-100 hover:cursor-default;
}
.data-table-body-tr-td {
  @apply py-3 px-3 border-b-2 border-r-2 border-gray-200 text-gray-900 text-[12px] font-body-font font-medium capitalize whitespace-nowrap;
}

/* data table style end */

/* dashboard components style start */

.logo-box {
  @apply px-2 py-4 flex flex-row items-center justify-start space-x-2 cursor-pointer;
}

.user-name {
  @apply whitespace-nowrap text-txt-white text-[25px] font-text-font font-medium truncate block;
}

/* dashboard components style end */
